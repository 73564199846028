





































import { Component, Vue } from "vue-property-decorator";
import AccountDataContext from "@/dataContexts/AccountDataContext";
import { CaptchaComponent } from "keiryo";
import { InputComponent } from "keiryo";
import { ValidationObserver } from "vee-validate";
import Settings from "@/Settings";

@Component({
    components: {
        CaptchaComponent,
        InputComponent
    },
})
export default class ForgotPasswordView extends Vue {
    private accountDataContext: AccountDataContext = new AccountDataContext();

    private loading = false;
    private changePassword = false;

    private email = "";
    private captchaResponse = "";

    private siteKey = Settings.HCaptcha.SiteKey;

    private async onSubmit() {
        const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>;

        if (this.captchaResponse && await observer.validate()) {
            this.loading = true;

            this.accountDataContext.forgotPassword(this.email, this.captchaResponse).then(x => {
                if (x.successfully) {
                    this.changePassword = true;
                }
            }).finally(() => this.loading = false);
        }
    }
}
